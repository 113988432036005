<template>
  <BaseLayout>
    <OtoTag
      :entity-id="'c3f4c0f0-afb3-4626-aba0-914ce5ab918d'"
      :entity-type="'bike'"
    />

    Trip Timeline: <br />
    Logs:
    <div v-for="(chunkedLogs, chunkIndex) in logs" :key="chunkIndex">
      <span class="bg-red-100">Chunk: {{ chunkIndex }}</span>
      <!-- {{ chunkedLogs }} -->
      <br />

      <div v-for="l in chunkedLogs" :key="l.id">
        <div>
          Parent: {{ l.action_type + ' ' + l.children.length || 'nope' }}
        </div>
        <div>TripID: {{ l.trip }}</div>
        <p v-for="child in l.children" :key="child.id">
          Children: {{ child.action_type }}
        </p>
        <br />
        <hr />
      </div>
    </div>

    <div
      class="px-3 py-4 mt-8 bg-white rounded"
      v-for="n in 1"
      :key="`group-${n}`"
    >
      <TimelineLogItem
        v-for="(log, logIndex) in logs"
        :key="`item-${logIndex}`"
        :variant="log.variant"
        :connected-with="
          logs.length === 1
            ? 'none'
            : logIndex === 0
            ? 'after'
            : logIndex === logs.length - 1
            ? 'before'
            : 'duo'
        "
        :icon-class="icons[log.icon]"
      >
        <template #visible>
          <TimelineLogLayerVisible
            html="The <a style='color: blue;'>Vehicle</a> is unlocked"
            :power-level="log.powerLevel"
            :request-status="log.requestStatus"
          />
        </template>

        <template #detailsContent>
          <TimelineLogLayerDetails :variant="`light`" />
        </template>
      </TimelineLogItem>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import TimelineLogItem from '@/components/timeline/TimelineLogItem.vue'
import TimelineLogLayerVisible from './TimelineLogLayerVisible.vue'
import TimelineLogLayerDetails from './TimelineLogLayerDetails.vue'
import OtoTag from '@/composites/tag/OtoTag.vue'
import logs from './logs'

export default {
  name: 'TL',

  components: {
    BaseLayout,
    TimelineLogItem,
    TimelineLogLayerVisible,
    TimelineLogLayerDetails,
    OtoTag,
  },

  data() {
    return {
      icons: {
        tick: 'fas fa-check-circle text-green-600',
        danger: 'fas fa-times-circle text-oRed',
        warning: 'fas fa-exclamation-circle text-oRed',
      },

      logItems: [
        {
          id: 1,
          variant: 'success',
          icon: 'tick',
          powerLevel: 70,
          requestStatus: '200',
        },
        {
          id: 2,
          variant: 'warning',
          icon: 'warning',
          powerLevel: 40,
          requestStatus: '200',
        },
        {
          id: 2,
          variant: 'secondary',
          icon: 'danger',
          powerLevel: 10,
          requestStatus: '200',
        },
      ],

      logs: logs,
    }
  },
}
</script>
<style lang="scss"></style>
