var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tags",staticClass:"tags"},[_c('input',{staticClass:"hidden-input-for-tabindex",attrs:{"type":"text","disabled":_vm.editPermission,"tabindex":_vm.tabIndex},on:{"focus":_vm.onSelect}}),_c('div',{staticClass:"tags__shadow",class:{ 'tags__shadow--tag-list-active': _vm.tagListActive }},[_c('div',{staticClass:"tags__active",class:{ 'tags__active--tag-list-active': _vm.tagListActive },on:{"click":_vm.onSelect}},[_vm._l((_vm.active),function(tag,index){return _c('div',{key:index,class:"tag",style:('background-color:' +
            (_vm.colorsEnabled && 'color' in tag ? tag.color : _vm.tagColorDefault))},[_c('span',{staticClass:"tag__name"},[_vm._v(_vm._s(tag.tag))]),(!_vm.deletePermission)?[(_vm.tagListActive)?_c('div',{staticClass:"tag__remove-button",on:{"click":function($event){return _vm.removeTag(tag)}}},[_c('svg',{attrs:{"viewBox":"0 0 8 8"}},[_c('polygon',{attrs:{"points":"8 1.01818182 6.98181818 0 4 2.98181818 1.01818182 0 0 1.01818182 2.98181818 4 0 6.98181818 1.01818182 8 4 5.01818182 6.98181818 8 8 6.98181818 5.01818182 4"}})])]):_vm._e()]:_vm._e()],2)}),_c('div',{staticClass:"tags__search-block"},[(("text")==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"tagInput",class:[
            "tags__search",
            _vm.active.length == 0 && 'bg-blue-500 placeholder-white rounded' ],attrs:{"tabindex":-1,"disabled":_vm.editPermission,"placeholder":_vm.computedPlaceholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.search)?_vm._i(_vm.search,null)>-1:(_vm.search)},on:{"change":function($event){var $$a=_vm.search,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.search=$$a.concat([$$v]))}else{$$i>-1&&(_vm.search=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.search=$$c}}}}):(("text")==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"tagInput",class:[
            "tags__search",
            _vm.active.length == 0 && 'bg-blue-500 placeholder-white rounded' ],attrs:{"tabindex":-1,"disabled":_vm.editPermission,"placeholder":_vm.computedPlaceholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.search,null)},on:{"change":function($event){_vm.search=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"tagInput",class:[
            "tags__search",
            _vm.active.length == 0 && 'bg-blue-500 placeholder-white rounded' ],attrs:{"tabindex":-1,"disabled":_vm.editPermission,"placeholder":_vm.computedPlaceholder,"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])],2),(_vm.tagListActive)?_c('div',{staticClass:"tags__list"},[_c('div',{staticClass:"tags__list-label"},[_c('span',[_vm._v(_vm._s(_vm.tagListLabel))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}],staticStyle:{"margin-right":"4px"}},[_c('i',{staticClass:"text-green-700 fas fa-spinner fa-pulse"})])]),_vm._l((_vm.filteredList),function(tag,index){return _c('div',{key:index,staticClass:"tags__list-item",on:{"click":function($event){return _vm.addTag(index)}}},[(_vm.tagCreationEnabled && tag.id === 0)?_c('span',{staticClass:"tags__create-tag-label",staticStyle:{"color":"#303030"}},[_vm._v(" Create ")]):_vm._e(),_c('div',{staticClass:"tags__list-item-tag",style:('background-color: #d3d3d3;')},[_c('span',[_vm._v(_vm._s(tag.tag))])])])})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }