<template>
  <div :class="`variants ${variant}`">
    <div class="flex items-center justify-between">
      <h3 class="font-weight: 500; font-size: 16px;">
        {{ title }}
      </h3>

      <span class="status-200"> req: {{ requestStatus }} </span>
    </div>

    <time class="text-xs text-gray-600">
      <i class=" fa fa-clock-o" aria-hidden="true" />
      {{ time }}
    </time>

    <slot
      name="expandable"
      v-bind="{ expandableSlotClass: getClassFor('expandableSlot') }"
    >
      <div :class="getClassFor('expandableSlot')">
        <!-- toggle -->
        <slot name="logsToggle">
          <button
            :class="getClassFor('logsToggle')"
            @click="toggleExpandable('logs')"
          >
            {{ isExpanded('logs') ? 'Hide' : 'Show' }}

            Log

            <svg
              width="17"
              height="8"
              viewBox="0 0 17 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                fill="#0954E4"
              />
            </svg>
          </button>
        </slot>
        <!-- /toggle -->

        <AccordionTransition :expanded="isExpanded('logs')">
          <slot name="logsContent">
            <pre class="logs-content" v-text="logs" />
          </slot>
        </AccordionTransition>
      </div>
    </slot>
  </div>
</template>
<script>
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'

export default {
  components: {
    AccordionTransition,
  },

  props: {
    variant: {
      type: String,
      default: 'none',
    },

    title: {
      type: String,
      default: 'The nice looking title is here',
    },
    time: {
      type: String,
      default: '6.40am',
    },
    requestStatus: {
      type: Number,
      default: 200,
    },
    logs: {
      type: String,
      default: `meta:
{
  count:
  {
    total: 16,
    page: 10
  },
  pagination:
  {
    limit: 10,
    offset: 0
  }
}`,
    },
  },
  data() {
    return {
      expandables: {
        logs: false,
      },
    }
  },

  methods: {
    getClassFor(term = '') {
      const map = {
        expandableSlot: `mt-2`,
        logsToggle: 'flex items-center text-blue-600 gap-2 text-sm',
      }

      return map[term] || `no-class-found-for:${term}`
    },
    isExpanded(term) {
      return this.expandables[term]
    },
    toggleExpandable(term) {
      this.expandables[term] = !this.expandables[term]
    },
  },
}
</script>

<style lang="scss" scoped>
.variants {
  @apply max-w-4xl p-4 mt-3;
  border-radius: 7px;

  &.light {
    background: #fff;
  }

  &.warning {
    background: #fff8e6;
  }
}

.logs-content {
  color: #fff;
  padding: 5px 8px;
  background: #000000;
  border: 1px solid #ffffff;
  border-radius: 7px;
}
.map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #12ceda;
}

.status-200 {
  color: #15893c;
  background: #dcf1e3;
  border: 1px solid #15893c;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 12px;
}
</style>
