<template>
  <div class="flex items-center justify-between">
    <div v-html="html" />

    <div class="flex items-center gap-5">
      <div>
        <BatteryStatus :level="powerLevel" />
      </div>

      <div class="map-icon">
        <i class="fas fa-map" @click="$emit('map', latLng)" />
      </div>

      <span class="status-200"> req: {{ requestStatus }} </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    BatteryStatus: () => import('@/components/badge/BatteryStatus.vue'),
  },

  props: {
    powerLevel: {
      type: Number,
      default: 0,
    },
    html: {
      type: String,
      default: '',
    },

    requestStatus: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #12ceda;
}

.status-200 {
  color: #15893c;
  background: #dcf1e3;
  border: 1px solid #15893c;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 12px;
}
</style>
