<template>
  <div class="tags" ref="tags">
    <input
      class="hidden-input-for-tabindex"
      type="text"
      :disabled="editPermission"
      :tabindex="tabIndex"
      @focus="onSelect"
    />

    <div
      class="tags__shadow"
      v-bind:class="{ 'tags__shadow--tag-list-active': tagListActive }"
    >
      <div
        class="tags__active"
        :class="{ 'tags__active--tag-list-active': tagListActive }"
        @click="onSelect"
      >
        <!-- tags-block -->
        <div
          v-for="(tag, index) in active"
          :key="index"
          :class="`tag`"
          :style="
            'background-color:' +
              (colorsEnabled && 'color' in tag ? tag.color : tagColorDefault)
          "
        >
          <span class="tag__name">{{ tag.tag }}</span>

          <template v-if="!deletePermission"
            ><div
              v-if="tagListActive"
              class="tag__remove-button"
              @click="removeTag(tag)"
            >
              <svg viewBox="0 0 8 8">
                <polygon
                  points="8 1.01818182 6.98181818 0 4 2.98181818 1.01818182 0 0 1.01818182 2.98181818 4 0 6.98181818 1.01818182 8 4 5.01818182 6.98181818 8 8 6.98181818 5.01818182 4"
                ></polygon>
              </svg></div
          ></template>
        </div>
        <!-- /tags-block -->

        <!-- search-block -->
        <div class="tags__search-block">
          <input
            v-model="search"
            ref="tagInput"
            :type="`text`"
            :tabindex="-1"
            :disabled="editPermission"
            :placeholder="computedPlaceholder"
            :class="[
              `tags__search`,
              active.length == 0 && 'bg-blue-500 placeholder-white rounded',
            ]"
          />
        </div>
        <!-- /search-block -->
      </div>

      <!-- tags-list -->
      <div v-if="tagListActive" class="tags__list">
        <div class="tags__list-label">
          <span>{{ tagListLabel }}</span>
          <span style="margin-right: 4px;" v-show="busy">
            <i class="text-green-700 fas fa-spinner fa-pulse" />
          </span>
        </div>

        <div
          class="tags__list-item"
          v-for="(tag, index) in filteredList"
          :key="index"
          @click="addTag(index)"
        >
          <span
            class="tags__create-tag-label"
            style="color: #303030"
            v-if="tagCreationEnabled && tag.id === 0"
          >
            Create
          </span>

          <div
            class="tags__list-item-tag"
            :style="'background-color: #d3d3d3;'"
          >
            <span>{{ tag.tag }}</span>
          </div>
        </div>
      </div>
      <!-- tags-list -->
    </div>
  </div>
</template>

<script>
import differenceBy from 'lodash/differenceBy'
/* eslint-disable no-prototype-builtins */
export default {
  name: 'SmartTag',

  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    // beforeCreating hook callback -> can be async && gets id & tag object
    beforeCreating: {
      type: Function,
      default: async ({ entityId, tag }) => {
        console.log({ entityId, tag })
        return true
      },
    },

    beforeAttaching: {
      type: Function,
      default: async ({ entityId, tag }) => {
        console.log({ entityId, tag })
        return true
      },
    },

    beforeDetaching: {
      type: Function,
      default: async ({ tagId, tag }) => {
        console.log({ tagId, tag })
        return true
      },
    },

    active: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((tag) => {
          // console.log('validate active tag', tag)
          return tag.hasOwnProperty('id') && tag.hasOwnProperty('tag')
        }),
    },

    all: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((tag) => {
          return tag.hasOwnProperty('id') && tag.hasOwnProperty('tag')
        }),
    },

    placeholder: String,

    elementCountForStartArrowScrolling: {
      type: Number,
      default: 3,
      validator: (v) => v >= 1,
    },

    tabIndex: {
      type: Number,
      default: 1,
      validator: (v) => v > 0,
    },

    tagCreationEnabled: Boolean,

    colorsEnabled: Boolean,

    colors: {
      type: Array,
      default: () => ['#0099ff', '#4cca3c', '#ff5460', '#963dff', '#FFB800'],
      validator: (v) =>
        v.every(
          (color) => typeof color === 'string' || color instanceof String
        ),
    },

    tagColorDefault: {
      type: String,
      default: '#333',
    },

    tagListLabel: {
      type: String,
      default: 'Select tags',
    },
    editPermission: {
      type: Boolean,
      default: false,
      required: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      //
      busy: false,

      //
      tagListActive: false,
      search: '',
      currentTagFromList: null,
      tagListClass: 'tags__list',
      tagClass: 'tags__list-item',
      tagFocusedClass: 'tags__list-item--focused',
      tagListElementHeight: 34,
      newTagColor: null,
      searchInputClass: 'tags__search',
    }
  },

  mounted() {
    window.addEventListener('click', (e) => this.handleClickOutsideTagList(e))
  },

  updated() {
    if (this.search.length > 0) {
      this.currentTagFromList = 0
    }
    if (this.tagCreationEnabled && this.search.length === 0) {
      this.newTagColor = null
    }
    this.updateSelection()
  },

  computed: {
    filteredList() {
      // let exclude = this.all.filter((tag) => {
      //   return !this.active.includes(tag)
      // })

      let diff = differenceBy(this.all, this.active, 'tag')

      let list = diff.filter(
        (tag) => tag.tag.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      )

      // keep maximum 5 results
      const sliced = list.slice(0, 5)

      // create new
      if (
        this.tagCreationEnabled &&
        this.search.length > 0 &&
        list.every((item) => {
          return item.tag !== this.search
        })
      ) {
        if (!this.newTagColor) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.newTagColor = this.getColorForNewTag()
        }
        // hasNew = true
        sliced.push({
          id: 0,
          tag: this.search,
          color: this.newTagColor,
        })
      }

      return sliced
    },

    computedPlaceholder() {
      if (this.active.length > 0) {
        return
      }
      return this.placeholder
    },
  },

  methods: {
    onSelect(e) {
      if (!this.editPermission) {
        setTimeout(
          () => document.querySelector('.' + this.searchInputClass).focus(),
          100
        )
        e.stopPropagation()
        if (!this.tagListActive) {
          // open
          this.$emit('on-tag-list-opened')
          this.tagListActive = true
        }
        document.onmouseover = () => {
          document.querySelectorAll('.' + this.tagClass).forEach((item, i) => {
            item.addEventListener(
              'mouseover',
              () => (this.currentTagFromList = i)
            )
          })
          this.updateSelection()
        }
        document.onkeydown = (e) => {
          switch (e.keyCode) {
            case 13:
              this.handleEnterKey()
              break
            case 8:
              this.handleBackspaceKey()
              break
            case 9:
              this.handleTabKey()
              break
            case 38:
              this.handleUpKey()
              this.updateSelection()
              break
            case 40:
              this.handleDownKey()
              this.updateSelection()
              break
          }
        }
      }
    },

    // beforeCreating() -> e:creating -> e:created
    // beforeAttaching() -> e:attaching -> e:attached
    // beforeDetaching() -> e:detaching -> e:detaching

    // create and/or attach
    async addTag(index) {
      if (this.tagSelected(index)) {
        return
      }

      const isCreating = this.filteredList[index].id === 0
      const entityId = this.id
      if (isCreating) {
        this.busy = true
        await this.beforeCreating({ entityId, tag: this.search })
          .then((created) => {
            console.log(
              'beforeCreatingTag: emitted[created, attached] ',
              created
            )
            this.$emit('created', created.data)
            this.$emit('attached', created.data)
          })
          .catch((creatingErr) => {
            console.log({ beforeCreatingErr: creatingErr })
          })
          .finally(() => (this.busy = false))

        if (this.tagCreationEnabled && this.search.length > 0) {
          this.newTagColor = null
        }
        // clear search filter
        this.search = ''
        this.$refs.tagInput.focus()

        return
      } else {
        const tag = this.filteredList[index]
        this.busy = true
        await this.beforeAttaching({ entityId, tag: tag.tag })
          .then((attached) => {
            console.log('beforeAttaching: emitted[attached] ', attached.data)
            this.$emit('attached', attached.data)
          })
          .catch((creatingErr) => {
            console.log({ beforeAttachingErr: creatingErr })
          })
          .finally(() => (this.busy = false))

        // clear search filter
        this.search = ''
        this.$refs.tagInput.focus()
      }
    },

    // detach
    // @param tag is the tag object {id: string, tag: string}
    async removeTag(tag) {
      // console.log('removeTag', tag)
      this.busy = true
      await this.beforeDetaching({ tagId: tag?.id, tag })
        .then(() => {
          console.log('beforeDetaching: emitted[detached]', tag)
          this.$emit('detached', tag)
        })
        .catch((err) => {
          console.log({ beforeDetachingErr: err })
        })
        .finally(() => (this.busy = false))
    },

    // accessibility
    async handleEnterKey() {
      if (!this.tagSelected(this.currentTagFromList)) {
        this.addTag(this.currentTagFromList)
      }
    },

    async handleBackspaceKey() {
      if (this.search.length === 1) {
        this.currentTagFromList = null
      }
      if (this.search.length < 1 && this.active.length > 0) {
        this.removeTag(this.active.length - 1)
      }
    },

    handleTabKey() {
      if (this.tagListActive) {
        this.tagListActive = false
        this.currentTagFromList = null
        this.search = ''
      }
    },

    handleUpKey() {
      let tagList = document.querySelector('.' + this.tagListClass)
      if (this.currentTagFromList === null || this.currentTagFromList <= 0) {
        this.currentTagFromList = this.filteredList.length - 1
        tagList.scrollTo(0, this.getScrollHeight())
      } else if (this.currentTagFromList > 0) {
        this.currentTagFromList--
      }
      if (
        this.filteredList.length - this.currentTagFromList >
        this.elementCountForStartArrowScrolling
      ) {
        tagList.scrollBy(0, -1 * this.tagListElementHeight)
      }
    },

    handleDownKey() {
      let tagList = document.querySelector('.' + this.tagListClass)
      if (
        this.currentTagFromList === null ||
        this.currentTagFromList === this.filteredList.length - 1
      ) {
        this.currentTagFromList = 0
        tagList.scrollTo(0, 0)
      } else if (
        this.currentTagFromList >= 0 &&
        this.currentTagFromList < this.filteredList.length - 1
      ) {
        this.currentTagFromList++
      }

      if (
        this.currentTagFromList >
        this.elementCountForStartArrowScrolling - 1
      ) {
        tagList.scrollBy(0, this.tagListElementHeight)
      }
    },

    handleClickOutsideTagList(e) {
      if (e.target.closest('.tags__list')) {
        return
      }
      if (this.tagListActive) {
        this.$emit('on-tag-list-closed')
      }
      document.onkeydown = null
      this.tagListActive = false
      this.currentTagFromList = null
      this.search = ''
    },

    // helpers
    tagSelected(index) {
      return this.active.some(
        (element) => element.tag === this.filteredList[index].tag
      )
    },

    updateSelection() {
      document.querySelectorAll('.' + this.tagClass).forEach((item, i) => {
        i === this.currentTagFromList
          ? item.classList.add(this.tagFocusedClass)
          : item.classList.remove(this.tagFocusedClass)
      })
    },

    getColorForNewTag() {
      if (!this.tagCreationEnabled) {
        return
      }
      return this.colorsEnabled
        ? this.colors[Math.floor(Math.random() * this.colors.length)]
        : this.tagColorDefault
    },

    getScrollHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin flex($direction, $wrap, $justify-content, $align-items) {
  display: flex;
  flex-flow: $direction $wrap;
  justify-content: $justify-content;
  align-items: $align-items;
}
@mixin font($font, $size, $weight, $color) {
  font-family: $font, Helvetica, sans-serif;
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
$primary-font: 'Source Sans Pro';
.hidden-input-for-tabindex {
  width: 0;
  height: 0;
}
.tags {
  position: relative;
  // width: 340px;
  width: 100%;
  height: 40px;
  &--active {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  &__active {
    @include flex(row, wrap, flex-start, flex-start);
    min-height: calc(40px - 12px);
    padding: 7px 7px 3px 7px;
    background-color: #fff;
    border-radius: 3px;
    // border: 0px solid #cfcfcf;
    // position: relative;
    // z-index: 5;
    &--tag-list-active {
      border-radius: 3px 3px 0 0;
    }
  }
  &__search-block {
    width: auto;
    margin: 0 0 0 -7px;
    display: flex;
    align-items: center;
    flex: 1 1 60px;
    min-width: 72px;
    // &:hover {
    //   background-color: rgb(229, 231, 235) !important;
    // }
  }
  &__search {
    width: 5rem;
    font-size: 13px;
    margin: 0;
    height: 24px;
    resize: none;
    padding: 10px;
    size: 1;
    &:only-child {
      margin: 0 0 4px 7px;
    }
    &:focus {
      outline: none;
      border: none;
      appearance: none;
    }
    &:hover {
      background-color: rgb(229, 231, 235) !important;
    }
  }
  &__list-label {
    @include flex(row, nowrap, space-between, center);
    width: 100%;
    padding: 7px 0 4px;
    border: 0px solid #cfcfcf;
    border-top: none;
    border-radius: 0 0 3px 3px;
    background-color: #f5f5f5;
    span {
      color: #aaa;
      font-size: 12px;
      margin: 0 0 3px 7px;
    }
  }

  &__list {
    @include flex(column, nowrap, flex-start, flex-start);
    width: 100%;
    padding: 7px 0;
    border: 0px solid #cfcfcf;
    border-top: none;
    border-radius: 0 0 3px 3px;
    background-color: #f5f5f5;
    span {
      color: #aaa;
      font-size: 12px;
      margin: 0 0 3px 7px;
    }
  }
  &__list-item {
    @include flex(row, nowrap, flex-start, flex-start);
    // width: calc(100% - 14px);
    width: 100%;
    color: black;
    min-height: 24px;
    padding: 5px 7px;
    cursor: pointer;
    &--focused {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  &__list-item-tag {
    @include flex(row, nowrap, flex-start, center);
    height: 24px;
    margin: 0 4px 4px 0;
    border-radius: 3px;
    padding: 0 10px 0 8px;
    background-color: #aaa;
    span {
      @include font($primary-font, 13px, 600, #303030);
      margin: 0 0 1px 0;
    }
    &:last-child {
      margin: 0;
    }
  }
  &__create-tag-label {
    margin: 0 7px 0 0 !important;
    line-height: 24px;
  }
  &__shadow {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    border-radius: 3px;
    &--tag-list-active {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    }
  }
}

.tag {
  @include flex(row, nowrap, flex-start, center);
  height: 24px;
  margin: 0 4px 4px 0;
  border-radius: 3px;
  padding: 0 9px 0 8px;
  background-color: #aaa;
  &__name {
    @include font($primary-font, 13px, 600, #fff);
    margin: 0 0 1px 0;
  }
  &__remove-button {
    width: 24px;
    height: 24px;
    margin: 0 -9px 0 0;
    align-items: center;
    user-select: none;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    &:hover > svg {
      opacity: 1;
    }
    svg {
      width: 8px;
      height: 8px;
      display: block;
      fill: #fff;
      flex-shrink: 0;
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out 0s;
    }
  }
}
</style>
