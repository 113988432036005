<template>
  <SmartTag
    :id="entityId"
    :all="existingTags"
    :active="attachedTags"
    :element-count-for-start-arrow-scrolling="3"
    :tab-index="1"
    :tag-creation-enabled="true"
    :colors-enabled="false"
    :tag-color-default="'#2563eb'"
    :tag-list-label="'Select a tag'"
    :editPermission="editPermissionStatus"
    :deletePermission="deletePermissionStatus"
    :placeholder="placeholderProxy"
    :before-creating="beforeCreatingTag"
    :before-attaching="beforeAttachingTag"
    :before-detaching="beforeDetachingTag"
    @created="onTagCreated"
    @attached="onTagAttached"
    @detached="onTagDetached"
  />
  <!--
    @on-tag-list-opened="onTagListOpened"
    @on-tag-list-closed="onTagListClosed"
  -->
</template>

<script>
import SmartTag from '@/components/form/SmartTag.vue'
import { serializeTagResponse } from '@/store/modules/live-map/live-map.helpers'
// import { useEndpoints } from '@/composables'
// import SweetMark from '@/components/form/SweetMark.vue'

export default {
  name: 'OtoTag',

  components: {
    SmartTag,
  },

  props: {
    entityId: {
      type: [String, Number],
      default: 0,
    },
    entityType: {
      type: String,
      default: '',
    },
    editPermissionStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
    deletePermissionStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      existingTags: [],
      attachedTags: [],

      placeholderProxy: '+Add tags',
    }
  },

  async created() {
    await this.fetchTags()
  },

  watch: {
    entityId: {
      deep: false,
      immediate: false, // we already fetch tags on created hook
      handler() {
        this.placeholderProxy = 'Loading...'
        this.fetchTags({ type: 'attached' }).then(() => {
          this.placeholderProxy = '+Add tags'
        })
      },
    },
  },

  methods: {
    // existing: all existing entity tags,
    // attached: active entity tags for the entityId
    // all: send all requests to the server
    async fetchTags({ type = 'all' } = {}) {
      const getUrl = (type) => {
        switch (type) {
          case 'existing':
            return this.$http.get(`/dashboard/entity-tags/${this.entityType}/`)
          case 'attached':
            return this.$http.get(
              `/dashboard/entity-tags/${this.entityType}/${this.entityId}/`
            )
          default:
            throw new Error(`fetchTags: Unknown type: ${type}`)
        }
      }

      const urls = []
      type === 'all' && urls.push(getUrl('existing'), getUrl('attached'))
      ;(type === 'existing' || type === 'attached') && urls.push(getUrl(type))

      return this.$http
        .all(urls)
        .then((res) => {
          const [resOne, resTwo] = res.map((r) => r.data.data)
          console.log(type, resOne, resTwo)

          if (type === 'all') {
            // the order should be maintained just like the urls[]
            this.existingTags = resOne.map((tag) => serializeTagResponse(tag))
            this.attachedTags = resTwo
          } else if (type === 'existing') {
            this.existingTags = resOne.map((tag) => serializeTagResponse(tag))
          } else if (type === 'attached') {
            this.attachedTags = resOne
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },

    async beforeCreatingTag({ entityId, tag }) {
      const url = `/dashboard/entity-tags/${this.entityType}/${entityId}/`
      const data = new FormData()
      data.append('tag', tag)

      console.log('beforeCreatingTag', entityId, tag)
      return await this.$http.post(url, data)
    },

    async beforeAttachingTag({ entityId, tag }) {
      // probably, the backend uses findOrCreate() or findOrAttach()
      return await this.beforeCreatingTag({ entityId, tag })
    },

    async beforeDetachingTag({ tagId }) {
      const url = `/dashboard/entity-tags/${tagId}/`

      return await this.$http.delete(url)
    },

    onTagCreated(tag) {
      // console.log({ created: tag })
      this.existingTags.push(tag)
    },

    onTagAttached(tag) {
      // console.log({ attached: tag })
      this.attachedTags.push(tag)
    },

    onTagDetached(tag) {
      // console.log({ data: tag })

      if (Array.isArray(this.attachedTags)) {
        const i = this.attachedTags.findIndex(
          (attachedTag) => attachedTag.id === tag.id
        )
        if (i !== -1) {
          this.attachedTags.splice(i, 1)
        }
      }
    },

    onTagListOpened(/* e */) {
      // console.log({ listOpened: e })
    },
    onTagListClosed(/* e */) {
      // console.log({ listClosed: e })
    },
  },
}
</script>

<style></style>
